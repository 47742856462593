import units from "@/base/lang/ru/units";

let unitNames = [
    'u',
    'g',
    'kg',
    't',
    'l',
    'cbm',
    'm',
    'sm',
    'mm',
    'pkg',
    'jr',
    'btl',
];

let unitFullNames = {
    m: 'meter',
    sm: 'centimeter',
    mm: 'millimeter',

    t: 'ton',
    kg: 'kilogram',
    g: 'gram',

    u: 'unit',

    cbm: 'cube_meter',
    l: 'liter',

    pkg: 'package',
    jr: 'jar',
    btl: 'bottle',
};

let helper = {
    plural:   function (str, number = 0) {
        let value = str.split('|');
        if (!value instanceof Array) {
            return str;
        }
        if (number === 0) {
            return value.length > 3 ? value[3] : str;
        }
        if (!Number.isInteger(number)) {
            return value.length > 1 ? value[1] : str;
        }
        let n = Math.abs(number);
        n %= 10;
        if (n >= 5 || n === 0) {
            return value.length > 2 ? value[2] : str;
        }
        if (n === 1) {
            return value[0];
        }
        if (n > 1 && n < 5) {
            return value.length > 1 ? value[1] : str;
        }
        return str;
    },
    ucfirst: function (str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    allUnits: function () {
        return unitNames.map(function (unitName) {
            return {
                name: unitName,
                title: units[unitName],
                fullTitle: helper.plural(units[unitFullNames[unitName]], 1),
                fullTitleMany: helper.plural(units[unitFullNames[unitName]]),
            }
        });
    },
    getUnitTitle: function (unitName, isFull = false, number = 0) {
        if (!isFull) {
            return units[unitName];
        }
        return helper.plural(units[unitFullNames[unitName]], number);
    },
    removeObjectFromArray: function(arr, key, value) {
        for (let i = arr.length - 1; i >= 0; i--) {
            if (arr[i][key] === value) {
                arr.splice(i, 1);
            }
        }
        return arr;
    },
    getCommissionValueTypeTitle: function (commission) {
        if (commission.value_type === 'percent') {
            return '%';
        }
        if (commission.value_type === 'fix') {
            return ' руб.';
        }
    },
    randomString: function(length) {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            result += charset[randomIndex];
        }
        return result;
    },
    sortArray: function(array, key, direction = 'asc') {
        return array.slice().sort((a, b) => {
            const aValue = a[key];
            const bValue = b[key];

            if (direction === 'asc') {
                if (aValue < bValue) return -1;
                if (aValue > bValue) return 1;
            } else if (direction === 'desc') {
                if (aValue > bValue) return -1;
                if (aValue < bValue) return 1;
            }

            return 0;
        });
    },
    sum: function (arr, field) {
        let sum = 0;
        arr.forEach(function (item) {
            sum += parseFloat(item[field] || 0);
        });
        return sum;
    },
    formatNumber: function (number, separator = ' ', decimalPlaces = 0) {
        const fixedNumber = number.toFixed(decimalPlaces);
        const parts = fixedNumber.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, separator);
        return parts.join('.');
    },
    getChanges: function (from, to) {
        if (!from) {
            from = {};
        }
        if (!to) {
            to = {};
        }

        const changes = {};

        const allKeys = new Set([...Object.keys(from), ...Object.keys(to)]);

        for (const key of allKeys) {
            const oldValue = from.hasOwnProperty(key) ? from[key] : null;
            const newValue = to.hasOwnProperty(key) ? to[key] : null;

            if (oldValue !== newValue) {
                changes[key] = {
                    field_name: key,
                    from: oldValue,
                    to: newValue
                };
            }
        }

        return changes;
    },
    addressString: function (address, showRegion = true, showArea = true, showStreet = true, showHouse = true) {
        let regionWithType = showRegion && address.region_with_type ? address.region_with_type : '';

        let areaWithType = '';
        if (regionWithType) {
            areaWithType = showArea && address.area_with_type ? ', ' + address.area_with_type : '';
        } else {
            areaWithType = showArea && address.area_with_type ? address.area_with_type : '';
        }

        let cityWithType = (regionWithType || areaWithType) ? ', ' + address.city_with_type : address.city_with_type;

        let result = regionWithType + areaWithType + cityWithType;

        result += showStreet && address.street_with_type ? ', ' + address.street_with_type : '';
        result += showStreet && showHouse && address.house ? ', ' + address.house : '';
        result += showStreet && showHouse && address.apartment ? ', ' + address.apartment : '';

        return result;
    },
    copyToClipboard(text) {
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'absolute';
        textarea.style.left = '-9999px';
        document.body.appendChild(textarea);
        textarea.select();
        try {
            document.execCommand('copy');
        } catch (err) {
            console.error('Ошибка при копировании', err);
        }
        document.body.removeChild(textarea);
    },
}

export default helper
