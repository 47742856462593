export default {
    state: () => ({
        user: {
            id: null,
            guest_id: null,
            name: null,
            phone: null,
            email: null,
            theme: null,
            balance: 0,
            roles: {},
            permissions: {},
            client: null,
            orders: [],
        }
    }),
    mutations: {
        load_user (state, data) {
            let user = {
                id: data.id,
                guest_id: data.guest_id,
                name: data.name,
                phone: data.phone,
                email: data.email,
                theme: data.theme,
                balance: data.balance,
                roles: data.roles,
                permissions: data.permissions,
                client: {},
                options: data.options ? data.options : {},
            };
            if (data.client) {
                user.client = {
                    id: data.client.id,
                    user_id: data.client.user_id,
                    guest_id: data.client.guest_id,
                    name: data.client.name,
                    options: data.client.options ? data.client.options : {},
                    addresses: [],
                    phones: [],
                    deleted_at: data.client.deleted_at,
                }
                if (data.client.phones && data.client.phones.length) {
                    data.client.phones.forEach((p) => user.client.phones.push(p));
                }
                if (data.client.addresses && data.client.addresses.length) {
                    data.client.addresses.forEach((a) => user.client.addresses.push(a));
                }
            }
            state.user = user;
        },
        add_address: (state, address) => {
            state.user.client.options.latest_address_id = address.id;
            state.user.client.addresses.push(address);
        },
        add_user_client_phone: (state, phone) => {
            state.user.client.phones.push(phone);
        },
        delete_user_client_phone: (state, phoneId) => {
            state.user.client.phones.splice(state.user.client.phones.findIndex((p) => p.id === phoneId), 1);
        },
    },
    actions: {
        load_user({ state, commit, rootState }, data) {
            commit('load_user', data);
        },
        add_address({ state, commit, rootState }, address) {
            commit('add_address', address);
        },
        add_user_client_phone({ state, commit, rootState }, phone) {
            commit('add_user_client_phone', phone);
        },
        delete_user_client_phone({ state, commit, rootState }, phoneId) {
            commit('delete_user_client_phone', phoneId);
        },
    },
    getters: {
        user(state) {
            return state.user;
        },
    }
}
