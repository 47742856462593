import {store} from "@/store";

export default {
    alert: function (message_text, message_type = 'info', message_delay = 10000) {
        store.dispatch('alert', {
            message_text,
            message_type,
            message_delay
        });
    }
};
