import axios from "axios";
import app from "@/modules/app";

window.axios = axios;

let host = window.appConfig.apiHost;
let baseUrl = host + 'api/';
let appUriPrefix = window.appConfig.uriPrefix;

axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['X-CSRF-TOKEN'] = window.csrf_token;
axios.defaults.headers.common['GID'] = window.appConfig.user.guest_id;

function requestUnauthorized() {
    location.href = host + 'login';
}

function requestForbidden403() {
    // location.replace('/special/forbidden');
}

function requestForbidden404() {
    // location.replace('/special/forbidden');
}

function runFallback(error, fallback, is_show_error_alerts) {
    if (error.response) {
        if (is_show_error_alerts || error.response.data || error.response.data.message) {
            showErrorAlerts(error.response);
        }
        if (typeof fallback === 'function') {
            fallback(error.response.data);
        }
    }
    if (error.request && error.request.status === 401) {
        requestUnauthorized();
        return;
    }
    if (error.request && error.request.status === 403) {
        requestForbidden403();
        return;
    }
    if (error.request && error.request.status === 404) {
        requestForbidden404();
        return;
    }
    console.log(error)
}

function showErrorAlerts(error) {
    if (error.data.message) {
        if (error.data.message.trim() === 'CSRF token mismatch.') {
            location.reload();
            return;
        }
        if (error.data.message.indexOf('SQLSTATE[HY000] [1045] Access denied for user') >= 0) {
            return;
        }
        app.alert(error.data.message, 'danger');
        return;
    }
    if (+error.status === 500) {
        return;
    }
    app.alert('Ошибка ' + error.status + '.', 'danger');
}

function getFullUrl(uri) {
    return uri.startsWith('/')
        ? baseUrl + appUriPrefix + (uri === '/' ? '' : uri)
        : baseUrl + (uri === '/' ? '' : uri);
}

export default {
    getHost: function () {
        return host;
    },
    getBaseUrl: function () {
        return baseUrl;
    },
    getAppUriPrefix: function () {
        return appUriPrefix;
    },
    get: function (uri, callback, fallback) {
        return axios.get(getFullUrl(uri))
            .then(response => {
                if (callback) callback(response.data);
                return response.data;
            })
            .catch(error => {
                if (fallback) fallback(error);
                throw error;
            });
    },
    post: function (uri, data, callback = null, fallback = null, is_show_error_alerts = true) {
        return axios.post(getFullUrl(uri), data)
            .then(response => {
                if (callback) callback(response.data);
                return response.data;
            })
            .catch(error => {
                runFallback(error, fallback, is_show_error_alerts);
                throw error;
            });
    },
    put: function (uri, data, callback = null, fallback = null, is_show_error_alerts = true) {
        data._method = 'PUT';
        return axios.post(getFullUrl(uri), data)
            .then(response => {
                if (callback) callback(response.data);
                return response.data;
            })
            .catch(error => {
                runFallback(error, fallback, is_show_error_alerts);
                throw error;
            });
    },
    delete: function (uri, data, callback = null, fallback = null, is_show_error_alerts = true) {
        data._method = 'DELETE';
        return axios.post(getFullUrl(uri), data)
            .then(response => {
                if (callback) callback(response.data);
                return response.data;
            })
            .catch(error => {
                runFallback(error, fallback, is_show_error_alerts);
                throw error;
            });
    }
};
