import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import user from '@/store/user';
import taskGroups from '@/modules/tasks/store/task_groups';
import alerts from '@/store/alerts';
import config from "@/store/config";
import shopCart from '@/store/shop_cart';

export const store = new Vuex.Store({
    modules: {
        user,
        shopCart,
        config,
        alerts,
        taskGroups,
    }
})
