import Vue from 'vue';
import { Modal } from 'bootstrap'; // Импортируем Modal из Bootstrap

require('@/global-components');
import store from '@/portal/store';
import ShopCartBtn from "@/portal/widgets/shop_cart/ShopCartBtn.vue";
import AddShopCartItemBtn from "@/portal/widgets/shop_cart/AddShopCartItemBtn.vue";

store.dispatch('load_user', window.appConfig.user).then(r => new Vue({
    el: '#app',
    store,
    components: {
        ShopCartBtn,
        AddShopCartItemBtn,
    },
    data() {
        return {
            toolbarWindow: null,
        };
    },
    created() {
        this.$http.get('shop-cart', (res) => {
            this.$store.dispatch('initShopCart', res);
        });

        // Добавляем обработчик popstate
        window.addEventListener('popstate', this.handlePopstate);

        // Добавляем начальное состояние в историю
        history.pushState(null, '', location.href);
    },
    beforeDestroy() {
        window.removeEventListener('popstate', this.handlePopstate);
    },
    methods: {
        handlePopstate(event) {
            const modal = document.querySelector('.modal.show');
            if (modal) {
                const modalInstance = Modal.getInstance(modal);
                if (modalInstance) {
                    modalInstance.hide();
                }
                setTimeout(() => {
                    history.pushState(null, '', location.href);
                }, 0);
            } else {
                return;
            }
        }
    }
}));
