export default {
    unit: 'штука|штуки|штук|поштучно',

    meter: 'метр|метра|метров|метры',
    centimeter: 'сантиметр|сантиметра|сантиметров|сантиметры',
    millimeter: 'миллиметр|миллиметра|миллиметров|миллиметры',

    ton: 'тонна|тонны|тонн|тонны',
    kilogram: 'килограмм|килограмма|килограммов|килограммы',
    gram: 'грамм|грамма|граммов|граммы',

    cube_meter: 'куб. метр|куб. метра|куб. метров|куб. метры',
    liter: 'литр|литра|литров|литры',

    package: 'упаковка|упаковки|упаковок|упаковки',
    jar: 'банка|банки|банок|банки',
    bottle: 'бутылка|бутылки|бутылок|бутылки',

    u: 'шт.',

    m: 'м.',
    sm: 'см.',
    mm: 'мм.',

    t: 'т.',
    kg: 'кг.',
    g: 'гр.',

    cbm: 'куб.м.',
    l: 'л.',

    pkg: 'уп.',
    jr: 'бан.',
    btl: 'бут.',
}
