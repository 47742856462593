import Vue from "vue";

const FieldText = () => import('./FieldText.vue');
Vue.component('FieldText', FieldText);

const FieldFloat = () => import('./FieldFloat.vue');
Vue.component('FieldFloat', FieldFloat);

const FieldSelect = () => import('./FieldSelect.vue');
Vue.component('FieldSelect', FieldSelect);

const FieldPhone = () => import('./FieldPhone.vue');
Vue.component('FieldPhone', FieldPhone);

const FieldInn = () => import('./FieldInn.vue');
Vue.component('FieldInn', FieldInn);

const FieldSwitch = () => import('./FieldSwitch.vue');
Vue.component('FieldSwitch', FieldSwitch);
