<template>
    <a class="item mx-3" href="/profile/shop-cart">
        <i class="bi bi-cart3 me-1"></i>
        Корзина
        <span v-if="$store.getters.getShopCartItems.length > 0" class="badge rounded-pill bg-light text-dark border border-secondary ms-2">{{ $store.getters.getShopCartItems.length }}</span>
    </a>
</template>

<script>
export default {
    name: "ShopCartBtn",
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<style scoped>

</style>
