export default {
    state: () => ({
        alerts: [],
    }),
    mutations: {
        alert (state, payload) {
            state.alerts.push(payload);
        },
        alert_remove (state, payload) {
            state.alerts = state.alerts.filter(item => item !== payload)
        }
    },
    actions: {
        alert({ state, commit, rootState }, payload) {
            commit('alert', payload);
        },
        alert_remove({ state, commit, rootState }, payload) {
            commit('alert_remove', payload);
        },
    },
    getters: {
        alerts(state) {
            return state.alerts;
        },
    }
}
