import $http from "@/http";

export default {
    state: () => ({
        taskGroups: null,
    }),
    mutations: {
        pushTaskGroup (state, taskGroup) {
            if (state.taskGroups === null) {
                state.taskGroups = [];
            }
            state.taskGroups.push({
                id: taskGroup.id,
                title: taskGroup.title,
                task_title: taskGroup.task_title,
                task_content: taskGroup.task_content,
            });
        },
    },
    actions: {
        loadTaskGroups({ state, commit, rootState }) {
            if (state.taskGroups === null) {
                state.taskGroups = [];
            }
            $http.get('/task_groups', function (res) {
                if (res.task_groups.length > 0) {
                    res.task_groups.forEach(function (taskGroup) {
                        commit('pushTaskGroup', taskGroup);
                    });
                }
            });
        },
    },
    getters: {
        taskGroups(state) {
            return state.taskGroups;
        },
    }
}
