export default {
    state: () => ({
        deviceType: 'mobile',
    }),
    mutations: {
        deviceType (state, payload) {
            state.deviceType = payload;
        },
    },
    actions: {
        resize({ state, commit, rootState }, payload) {
            if (document.documentElement.clientWidth < 768 && state.deviceType !== 'mobile') {
                commit('deviceType', 'mobile');
            }
            if (document.documentElement.clientWidth >= 768 && document.documentElement.clientWidth < 992 && state.deviceType !== 'tablet') {
                commit('deviceType', 'tablet');
            }
            if (document.documentElement.clientWidth >= 992 && state.deviceType !== 'desktop') {
                commit('deviceType', 'desktop');
            }
        },
    },
    getters: {
        deviceType(state) {
            return state.deviceType;
        },
    }
}
